<template>
    
      <!-- Main content -->
      <section class="content">
        <div class="btn-box">
            <el-form :inline="true">
                <el-form-item label="用户user_id：">
                    <el-input v-model="uid" placeholder="请输入user_id"></el-input>
                </el-form-item>
                <el-form-item label="备注：">
                    <el-input v-model="remark" placeholder="请输入备注"></el-input>
                </el-form-item>
                <el-form-item label="类型：">
                    <el-select v-model="type" placeholder="请选择">
                        <el-option
                        v-for="item in type_list"
                        :key="item.type"
                        :label="item.name"
                        :value="item.type">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="来源：">
                    <el-select v-model="source_type" placeholder="请选择">
                        <el-option
                        v-for="item in source_type_list"
                        :key="item.type"
                        :label="item.name"
                        :value="item.type">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="表源：">
                    <el-select v-model="table" placeholder="请选择">
                        <el-option
                        v-for="item in table_list"
                        :key="item.type"
                        :label="item.name"
                        :value="item.type">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="seach(1)">搜索</el-button></el-button> 
                </el-form-item>
            </el-form>
            <span class="tip">* 请输入具体用户user_id查询</span>
        </div>
        <el-table
            v-loading="loading"
            :data="incomeList"
            border>
            <el-table-column
                fixed
                prop="id"
                label="编号"
                width="100"
            >
            </el-table-column>
            <el-table-column
                label="user_id（user_key）"
                width="200"
                fixed
            >
            <template slot-scope="scope">
                {{ scope.row.user_id}}({{scope.row.user_key}})
            </template>
</el-table-column>
<el-table-column prop="amount" label="金额"></el-table-column>
<el-table-column label="时间">
    <template slot-scope="scope">
        {{scope.row.create_time | dateTime}}
    </template>
</el-table-column>
<el-table-column prop="remark" label="备注"></el-table-column>
<el-table-column label="类型" width="100px">
    <template slot-scope="scope">
        {{scope.row.type | type}}
    </template>
</el-table-column>
<el-table-column label="入账后余额" width="100">
    <template slot-scope="scope">
        <div v-if="scope.row.type == 1" class="tip">
        {{Number(Number(scope.row.balance) + Number(scope.row.amount)).toFixed(2)}}
        </div>
        <div v-else>
        {{scope.row.balance}}
        </div>
    </template>
</el-table-column>
<el-table-column label="入账后积分" width="100">
    <template slot-scope="scope">
        <div v-if="scope.row.type == 2" class="tip">
        {{Number(Number(scope.row.score) + Number(scope.row.amount)).toFixed(2)}}
        </div>
        <div v-else>
        {{scope.row.score}}
        </div>
    </template>
</el-table-column>
<el-table-column label="入账后免单点" width="120">
    <template slot-scope="scope">
        <div v-if="scope.row.type == 3" class="tip">
        {{Number(Number(scope.row.free_order_point) + Number(scope.row.amount)).toFixed(2)}}
        </div>
        <div v-else>
        {{scope.row.free_order_point}}
        </div>
    </template>
</el-table-column>
<el-table-column label="来源" width="100">
    <template slot-scope="scope">
        {{scope.row.source_type | source}}
    </template>
</el-table-column>
</el-table>
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange">
</el-pagination>

</section>
<!-- /.content -->
</template>

<script>
    import * as api from '@/config/api'
    let common = JSON.parse(window.localStorage.getItem('common'))
    export default {
        name: 'income',
        components: {},
        data() {
            return {
                incomeList: [],
                table_list: common.table_list,
                source_type_list: [...[{
                    type: '',
                    name: '全部'
                }], ...common.source_type_list],
                type_list: common.bill_type_list,
                total: 0,
                page: 1,
                pagesize: 10,
                uid: '',
                remark: '',
                source_type: '',
                type: '',
                table: '0',
                loading: true,

            }
        },
        watch: {},
        filters: {
            source(val) {
                let name = ''
                if (JSON.stringify(common.source_type_list).indexOf(val) != -1) {
                    common.source_type_list.map((a, index) => {
                        if (val === a.type) {
                            name = a.name
                        }
                    })
                }
                return name
            },
            type(val) {
                let name = ''
                if (JSON.stringify(common.bill_type_list).indexOf(val) != -1) {
                    common.bill_type_list.map((a, index) => {
                        if (val === a.type) {
                            name = a.name
                        }
                    })
                }
                return name
            },
            dateTime(val) {
                let day = new Date(Number(val) * 1000)
                if (Number(val) === 0) {
                    return ''
                }
                return day.getFullYear() + '-' + `${(day.getMonth() + 1) < 10 ? '0' + (day.getMonth() + 1) : (day.getMonth() + 1)}` + '-' + `${day.getDate()<10?'0'+day.getDate():day.getDate()}` + ' ' + `${day.getHours()<10?'0'+day.getHours():day.getHours()}` + ':' + `${day.getMinutes()<10?'0'+day.getMinutes():day.getMinutes()}` + ':' + `${day.getSeconds()<10?'0'+day.getSeconds():day.getSeconds()}`
            }
        },
        mounted() {
            this.getIncome()
        },
        methods: {
            close() {
                this.show = false
                this.getIncome()
            },
            getIncome(page, size) {
                this.loading = true
                api.getIncome({
                    user_id: this.uid,
                    remark: this.remark,
                    source_type: this.source_type,
                    type: this.type,
                    table_num: this.table,
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize
                }, res => {
                    this.incomeList = res.data.list
                    this.total = Number(res.data.count)
                    this.loading = false
                })
            },
            sizeChange(val) {
                this.pagesize = val
                this.getIncome()
            },
            currentChange(val) {
                this.page = val
                this.getIncome()
            },
            seach(page, size) {
                this.getIncome(page, size)
            }
        }
    }
</script>
<style scoped>
    .tip {
        color: #ff5050;
    }
    
    .cell {
        text-align: center;
    }
    
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    img {
        width: 150px;
    }
</style>